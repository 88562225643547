import Homepage from './home/home'
import Programpage from './programpodcast/program'
import Podcastdetail from './programpodcast/podcastdetail'
import Broadcastpage from './broadcast/broadcast'
import Frequencypage from './frequency/frequency'
import Top20page from './top20/top20'
import Newsgallerypage from './newsgallery/newsgallery'
import Newsdetail from './newsgallery/newsdetail'

export {
    Homepage,
    Programpage,
    Podcastdetail,
    Broadcastpage,
    Frequencypage,
    Top20page,
    Newsgallerypage,
    Newsdetail
}
