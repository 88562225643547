import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import App from "./App";

import registerServiceWorker from "./registerServiceWorker";

import rootReducer from "./reducers";
import { fetchConfig } from "./common/helper";
import { setConfig } from "./redux/radio/action";
import Page404 from "./common/404";

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
fetchConfig().then(config => {
  if (config != null) {
    setConfig(config)
    const client = new ApolloClient({
      link: new HttpLink({
        uri: config.MobilApiUrl
      }),
      cache: new InMemoryCache()
    });

    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>,
      document.getElementById("root")
    );

    registerServiceWorker();
  } else {
    console.error("CONFIG", "Config bilgileri alınamadı: " + window.location.href + 'config.json')
    ReactDOM.render(<Page404 />, document.getElementById("root"))
  }

});
