import React, { Component } from "react";
import LoadingImage from "../img/loading-card-image.png";
import swiperParams from "./swiperParams";
import CustomSwiper from "./customswiper";
const tempArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

class SwiperLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <h2 style={{ marginBottom: 17 }} className="section-title">
          Yükleniyor...
        </h2>
        <CustomSwiper {...swiperParams}>
          {tempArray.map((item, index) => {
            return (
              <div key={index} className="swiper-slide">
                <div className="default-card">
                  <figure className="figure">
                    <div className="img-wrap">
                      <img src={LoadingImage} alt="RadyoD" />
                    </div>
                    <figcaption className="caption">
                      <h3 className="title">Yükleniyor..</h3>
                      <p className="spot">Yükleniyor</p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            );
          })}
        </CustomSwiper>
      </>
    );
  }
}

export default SwiperLoading;
