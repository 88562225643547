import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import live from '../../img/live.svg';

class PodcastCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      personName: ""
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      content: this.props.contentType,
    });

    fetch(this.props.config.LiveRadioInfoUrl).then(res => res.json())
    .then(data => {
      this.setState({personName: data?.ProgramName});
    })
  }

  getLiveContent = () => {
    let personName = this.state.personName.substring(0,3).toLocaleLowerCase();
    let personName2 = this.props.title?.substring(0,3).toLocaleLowerCase();
    return personName === personName2
  }

  render() {
    return (
      <div key={this.props.index} className="swiper-slide">
      <Link className="new-card" to={this.props.url}>
        <figure className="figure">
          <div className="img-wrap">
          <img src={this.props.imagePath} alt={this.props.title} />
          </div>
          {this.getLiveContent() &&
            <figcaption className="caption">
              <img src={live} alt={this.props.title} />
              <p className="live">CANLI</p>
            </figcaption>}
        </figure>
        <div className="content">
          <h3>{this.props.title}</h3>
          {this.props.description &&
            <div className="time">
              <p className="text">{this.props.description}</p>
            </div>
          }
        </div>
      </Link>
  </div>

    );
  }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const podcastCard = withRouter(props => <PodcastCard {...props} />)
const podcastCardConnect = connect(mapStateToProps)(podcastCard);

export default podcastCardConnect;