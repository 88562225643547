import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import HtmlParse from 'html-react-parser'

import dreamtv from '../../img/logo-dreamtv@3x.png'
import dreamturk from '../../img/logo-dream-turk-93x93px.png'
import cnnturk from '../../img/logo-cnnturk@3x.png'
import playstore from '../../img/logo-playstore@3x.png'
import appstore from '../../img/logo-appstore@3x.png'
import { HEADER_FOOTER_QUERY, HEADER_FOOTER_VARIABLES } from '../../query/footer'
import { isInternalUrl } from '../../common/helper'
import { connect } from 'react-redux'
import { SocialMediaAccounts } from '../social-media-accounts'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {}    }


    render() {
        const { config } = this.props
        return (
            <footer>
                <div className="share-apps">
                    <div className="share-apps-items social">
                        <div className="social-title">Bizi Takip Edin</div>
                        <SocialMediaAccounts />
                    </div>
                    <div className="share-apps-items stores">
                        <a
                            href={config.PlayStoreUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="link googleplay"
                        >
                            <img src={playstore} alt="Play Store" />
                        </a>
                        <a
                            href={config.AppStoreUrl}
                            target="_blank"
                            className="link appstore"
                            rel="noopener noreferrer"
                        >
                            <img src={appstore} alt="App Store" />
                        </a>
                    </div>

                    <div className="footer-nav">
                        <Query variables={HEADER_FOOTER_VARIABLES} query={HEADER_FOOTER_QUERY}>
                            {({ data, loading }) => {
                                if (loading || !data) {
                                    return (
                                        <Link to={'#'} key={'loading'}>
                                            YÜKLENİYOR...
                                        </Link>
                                    )
                                }

                                var menuData = data.Menu.Template.Regions.find(a => a.IxName == 'Footer')?.Controls[0].Controls || []

                                return (
                                    <>
                                        {menuData.map(item =>
                                            isInternalUrl(item.Properties[1].Value) ? (
                                                <Link to={item.Properties[1].Value} key={item.Properties[0].Value}>
                                                    {HtmlParse(item.Properties[0].Value)}
                                                </Link>
                                            ) : (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={item.Properties[1].Value}
                                                    key={item.Properties[0].Value}
                                                >
                                                    {HtmlParse(item.Properties[0].Value)}
                                                </a>
                                            )
                                        )}
                                    </>
                                )
                            }}
                        </Query>
                    </div>
                    <div className="share-apps-items partners">
                        <a
                            href={config.DreamTvUrl}
                            target="_blank"
                            className="partners-link dreamtv"
                            rel="noopener noreferrer"
                        >
                            <img src={dreamtv} alt="DreamTv" />
                        </a>
                        <a
                            href={config.DreamTurkUrl}
                            target="_blank"
                            className="partners-link dreamturk"
                            rel="noopener noreferrer"
                        >
                            <img src={dreamturk} alt="DreamTurk" />
                        </a>
                        <a
                            href={config.CnnTurkRadioServicesUrl}
                            target="_blank"
                            className="partners-link cnn"
                            rel="noopener noreferrer"
                        >
                            <img src={cnnturk} alt="CnnTurk" />
                        </a>
                    </div>
                </div>

                <div className="copyright">
                    <span>
                        RADYO D © 2019. Her Hakkı Saklıdır. Bilgi Toplumu Hizmetleri MKK tarafından sağlanmaktadır.
                    </span>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = (state) => ({...state.radioReducer})
const footer = withRouter(props => <Footer {...props} />)
const FooterWithRouter = connect(mapStateToProps)(footer);

export default FooterWithRouter;
