import React, { Component } from 'react'
import { connect } from 'react-redux'
import logo from '../../img/radyodlogo.png'
import { fetchRadioValue } from '../../common/helper'
import { setPlayingState, setRadioData } from '../../redux/radio/action'
import { withRouter } from 'react-router-dom'
import RadyodPlayer from '../player/RadyodPlayer'

class IFrameLarge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            active: false,
            playing: false,
        }
    }

    componentDidMount() {
        fetchRadioValue(this.props.config.LiveRadioInfoUrl, (radioData) => setRadioData(radioData))
        setInterval(() => {
            fetchRadioValue(this.props.config.LiveRadioInfoUrl, (radioData) => setRadioData(radioData))
        }, 15000)
    }

    onClickPlay = (playing) => {
        if (playing)
            setPlayingState({
                page: 'layout',
                item: this.props.config.StreamUrl,
                index: 0,
            })
        this.setState({ playing })
    }

    render() {
        const { radioData } = this.props
        return (
            <div className='iframe-large-container'>
                <div className='iframe-programImage'>
                    <img src={radioData.programImage} alt='Radyo D' />
                </div>
                <img className='iframe-logo' alt='radyod' src={logo}></img>
                <div className='iframe-program-title'>{radioData.nextProgramName}</div>
                <div className='iframe-songDetails'>
                    <div className='iframe-songImage'>
                        <img src={radioData.songImage} alt='Radyo D' />
                    </div>
                    <div className='iframe-songName'>{radioData.songName}</div>

                    <div className='iframe-radyodPlayer'>
                        <div id='radyodPlayer'></div>
                        <RadyodPlayer
                            onClickPlay={() => this.onClickPlay(true)}
                            onPause={() => this.setState({ playing: false })}
                            onEnded={() => this.setState({ playing: false })}
                            playing={this.props.playingState.page === 'layout' && this.state.playing}
                            url={this.props.config.StreamUrl}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const iframeLarge = withRouter(props => <IFrameLarge {...props} />)
export default connect(mapStateToProps)(iframeLarge)
