import React, { Component } from 'react'
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import LoadingImage from '../../img/loading-card-image.png';
import { EVENTS_QUERY, EVENTSCOUNT_QUERY } from '../../query/events';
import Pagination from '../../common/pagination';
import HtmlParse from "html-react-parser";
import { Helmet } from 'react-helmet';

export default class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            pageSize: 12,
            variables: {
                filter: "Ancestors/any(a: a/SelfPath eq '/etkinlikler/') and ContentType eq 'PhotoGallery'",
                q: '',
                orderBy: 'StartDate desc',
            },
            pageVariables: {
                top: 12,
                skip: 0,
            },
            displayMenu: false,
            selectedMonthValue: 0,
        };

        this.months = ['Tümü', ...Array.from({length: 12}, (v, k) => new Date(1970, k, 1).toLocaleString('tr-TR', {month: 'long'}))];

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
    }

    onchangePage = selectedPage => {
        if (this.state.currentPage !== selectedPage) {
            this.setState({
                currentPage: selectedPage,
                pageVariables: {
                    top: this.state.pageSize,
                    skip: (selectedPage - 1) * this.state.pageSize
                },
            });
        }
    };

    onchangeMonth = selectedMonthValue => {
        if (this.state.selectedMonthValue !== selectedMonthValue) {
            let currentYear = new Date().getFullYear();

            this.setState({
                selectedMonthValue: selectedMonthValue,
                variables: {
                    filter: selectedMonthValue === 0
                        ? "Ancestors/any(a: a/SelfPath eq '/etkinlikler/') and ContentType eq 'PhotoGallery'"
                        : "Ancestors/any(a: a/SelfPath eq '/etkinlikler/') and ContentType eq 'PhotoGallery' and StartDate gt datetime'" + new Date(currentYear, selectedMonthValue - 1, 1).toISOString() + "' and StartDate lt datetime'" + new Date(currentYear, selectedMonthValue, 1).toISOString() + "'",
                    q: '',
                    orderBy: 'StartDate desc',
                },
                currentPage: 1,
                pageVariables: {
                    top: this.state.pageSize,
                    skip: 0,
                },
            });
        }
    };

    showDropdownMenu = event => {
        event.preventDefault();
        this.setState({displayMenu: true}, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    };

    hideDropdownMenu = () => {
        this.setState({displayMenu: false}, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });
    };

    render() {
        return (
            <div className="events-container">
            <Helmet>
                <title>Etkinlikler - Radyo D</title>
            </Helmet>
                <Query variables={{...this.state.variables, ...this.state.pageVariables}} query={EVENTS_QUERY}>
                    {({data, loading, error}) => {
                        if (loading || !data) {
                            return <Loading/>;
                        } else if (error) {
                            console.error(error);
                        } else {
                            return (
                                <>
                                    <div className="events-header">
                                        <h2 className="cat-title">
                                            ETKİNLİKLER
                                        </h2>
                                        <div className="events-dropdown rd-dropdown">
                                            <button onClick={this.showDropdownMenu} className={this.state.displayMenu ? 'select-month active' : 'select-month'}>
                                                Ay Seç: {this.months[this.state.selectedMonthValue]}
                                                <i className="fa fa-angle-down"></i>
                                            </button>
                                            {this.state.displayMenu ? (
                                                <div className="breadcrumb-sub-nav rd-dropdown-list">
                                                    {this.months.map((item, index) => {
                                                        return (
                                                            <button
                                                                key={index}
                                                                value={index}
                                                                onClick={e => this.onchangeMonth(+e.target.value)}
                                                                type="button"
                                                                // eslint-disable-next-line eqeqeq
                                                                className={this.state.selectedMonthValue === index ? 'link active' : 'link'}
                                                            >
                                                                {item}
                                                            </button>
                                                        );
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row events-list">
                                        {data.Filter.map((item, index) => {
                                            var date = new Date(item.Properties.find(x => x.IxName === 'EventTime')?.Value || new Date(item.StartDate).toLocaleString('en-US', {timeZone: 'UTC'}));
                                            return (
                                                <div key={index} className="item col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div className="events-card">
                                                        <div className="img-wrap">
                                                            <span className="bg-image" style={{backgroundImage: item.Files.length < 1 ? "url('" + LoadingImage + "')" : "url('" + item.Files[0].Url + "')"}}></span>
                                                        </div>
                                                        <div className="content-wrap">
                                                            <div className="meta">
                                                                <span className="date">
                                                                    <i className="far fa-calendar-alt"></i>
                                                                    <span>{date.getDate()} {date.toLocaleString('tr-TR', {month: 'long'})} {date.toLocaleString('tr-TR', {weekday: 'short'})} - {date.toLocaleTimeString('tr-TR', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit'
                                                                    })}</span>
                                                                </span>
                                                                <span className="location">
                                                                    <i className="fa fa-map-marker-alt"></i>
                                                                    <span>{item.Description}</span>
                                                                </span>
                                                            </div>
                                                            <div className="desc">
                                                                <span className="spot">{item.Title}</span>
                                                                {item.Text &&
                                                                    <span className="detail">, {HtmlParse(item.Text)}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {data.Filter.length < 1 &&
                                            <div className="col-12">
                                                <div className="no-events">
                                                    <div className="bg-noevent"></div>
                                                    <p>Seçtiğiniz Ay İçin Etkinlik Yoktur.</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Query variables={this.state.variables} query={EVENTSCOUNT_QUERY}>
                                        {({data, loading, error}) => {
                                            if (loading || !data) {
                                                return <Loading/>;
                                            } else if (error) console.error(error);
                                            else {
                                                const count = data.FilterCount.Count;
                                                return (
                                                    <Pagination
                                                        onchangePage={this.onchangePage}
                                                        current={this.state.currentPage}
                                                        itemCount={count}
                                                        pageSize={this.state.pageSize}
                                                    />
                                                );
                                            }
                                        }}
                                    </Query>
                                </>
                            )
                                ;
                        }
                    }}
                </Query>
            </div>
        )
            ;
    }
    }
